import './store';
import Step1 from './pages/Step1.container';
import Step2 from './pages/Step2.container';
import Step3 from './pages/Step3.container';
import Step4 from './pages/Step4.container';
import Step5 from './pages/Step5.container';
import Step6 from './pages/Step6.container';

export {
	Step1,
	Step2,
	Step3,
	Step4,
	Step5,
	Step6
};
